*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container{
    display:flex;
}
main{
    width: 100%;
    padding-top: 100px;
}
.sidebar{
    background:white;
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
    border-right: 1px solid black;
    overflow-y: auto;
}
.top_section{
  display: flex;
  align-items: center;
  justify-content: center; /* Align items in center horizontally */
  padding: 20px 10px; /* Adjusted padding */
}
.logo{
    font-size: 25px;
    color: green;
    
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
    color: green;
}
.link{
    display: flex;
    color: green;
    padding: 10px 15px;
    gap: 25px;
    transition: all 0.5s;
    margin-top: 5px;
}
.link:hover{
    background:lightskyblue;
    color:green;
    transition: all 0.5s;
}
.active{
    background: lightskyblue;
    color: green;
}
.icon, .link_text{
    font-size: 18px;
}
.customDatePicker .MuiInputBase-root {
    height: 40px;
    width: 450px; 
    margin-bottom: 10px;
  }