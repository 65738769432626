.cardImg {
    width:620px;
    height: 290px;
    box-shadow: none;
  }
  .cartContent {
    display: inline-block;
    transition: transform 0.5s;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      margin-left: 1rem;
      margin-top: 4px;
  }
  .cartContent:hover {
    box-shadow: 0 3px 10px 0 gray;
  }
  
  .mainitem {
    display: flex;
    gap: 3.1rem;
    flex-wrap: wrap;
  }
  .bannerImg {
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-items: center;
  }
  .bannerContainer.css-1j4dda6-MuiGrid-root {
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
      rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    border-radius: 12px;
    padding: 1rem 1rem;
    margin-left:-13px;
  }
  .bannerTxt {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .grid.css-1j4dda6-MuiGrid-root > .MuiGrid-item {
    padding-left: 2px;
    padding-top: 3rem;
  }
  .upload.css-mm42gz-MuiButtonBase-root-MuiFab-root {
    background-color: rgb(99, 102, 241);
  }
  .upload.css-mm42gz-MuiButtonBase-root-MuiFab-root:hover {
    background-color: rgb(20, 23, 196);
  }
  
  .switch.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .MuiSwitch-input {
    color: aqua;
  }
  
  .switchTrackTrue {
    background-color: rgb(99, 102, 241) !important;
  }
  
  .switchTrackFalse {
    background-color: rgb(108, 115, 127) !important;
  }
  
  .switchTrackTrue {
    background-color: rgb(99, 102, 241) !important;
  }
  
  .switchTrackFalse {
    background-color: rgb(108, 115, 127) !important;
  }
  
  .delBtn.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    background-color: rgb(99, 102, 241);
    color: white;
    font-size: 13px;
    padding: 5px 20px;
    font-weight: 700;
    border-radius: 12px;
    text-transform: capitalize;
    letter-spacing: 1px;
  }
  .delBtn.css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgb(69, 72, 241);
    color: white;
    font-size: 13px;
    font-weight: bold;
  }
  .contentCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  